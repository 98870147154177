import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#000',
        },
        secondary: {
            main: '#F8E052',
        },
        primary: {
            main: '#F8E052',
        },
    },
});

import { Box, Button, Typography } from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react";

export interface iNativeMessage {
    type: string,
    tag?: number,
    tsPlayerEventCaptured?: number,
    tsPlayerSentToHost?: number,
    tsHostReceivedFromPlayer?: number,
    tsHostSentToExtension?: number,
    payload?: any
}

export const usePlayjector = (ext: string) => {

    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [heartbeatTimer, setHeartbeatTimer] = useState<number>(0);
    const [showDebug, setShowDebug] = useState<boolean>(false);

    const extension = useRef<string>(ext);
    const port = useRef<chrome.runtime.Port | null>(null);
    const nextTag = useRef<number>(0);

    const playject = useCallback((type: string, tag?: boolean, payload?: any) => {
        // Not sure why anything but heartbeat is showing isConnected === false
        // if (!isConnected) return;
        const message: iNativeMessage = {
            type: type,
        }

        if (tag) {
            message.tag = nextTag.current;
            nextTag.current++;
        }

        if (payload) {
            message.payload = payload;
            message.tsPlayerEventCaptured = payload.tsPlayerEventCaptured || 0;
            message.tsPlayerSentToHost = payload.tsPlayerSentToHost || 0;
            message.tsHostReceivedFromPlayer = payload.tsHostReceivedFromPlayer || 0;
            message.tsHostSentToExtension = Date.now();
        }
        // console.log('message final form', message);
        port.current?.postMessage(message);
    }, []);

    useEffect(() => {
        if (!isConnected || !(heartbeatTimer > 0)) return;
        const i = setInterval(() => {
            playject("heartbeat");
        }, heartbeatTimer);
        return () => clearInterval(i);
    }, [heartbeatTimer, isConnected, playject]);

    const setHeartbeat = (time: number) => {
        if (time < 1) {
            setHeartbeatTimer(0);
        } else {
            // Don't allow heartbeat faster than 1 second
            setHeartbeatTimer(Math.max(time, 1000));
        }
    }

    const connect = (hb: number = 1000) => {
        setHeartbeatTimer(hb);
        if (isConnected) return;
        port.current = chrome.runtime.connect(extension.current, { name: 'PlaycastHost' });
        port.current.postMessage({ type: "connect" });
        port.current.onMessage.addListener((message, port) => {
            console.debug('From background: ', message);
            if (message === 'disconnect') {
                setIsConnected(false);
                setShowDebug(false);
                setHeartbeatTimer(0);
            }
        });

        port.current.onDisconnect.addListener((port) => {
            console.log('Disconnected from background');
            setIsConnected(false);
        });

        console.log('Connected to Playjector');
        setIsConnected(true);
    }

    const disconnect = () => {
        playject("disconnect");
    }

    const test = (quantity: number) => {
        playject("test", false, { quantity: quantity });
    }

    const debug = () => {
        playject("debug");
        setShowDebug(!showDebug);
    }

    const benchmark = () => {
        playject("benchmark");
        setShowDebug(!showDebug);
    }

    const sendGamepadInput = (data: any) => {
        playject("gamepad", true, data);
    }

    const sendKeyInput = (data: any) => {
        // console.log('playjector: sending key input');
        playject("key", true, data);
    }

    const sendMouseInput = (data: any) => {
        playject("mouse", true, data);
    }

    useEffect(() => {
        window.addEventListener('beforeunload', disconnect);
        return () => {
            window.removeEventListener('beforeunload', disconnect);
        }
    });

    return {
        extension: extension.current,
        isConnected: isConnected,
        showDebug: showDebug,
        connect: connect,
        disconnect: disconnect,
        setHeartbeat: setHeartbeat,
        heartbeat: (heartbeatTimer > 0),
        test: test,
        debug: debug,
        benchmark: benchmark,
        sendGamepadInput: sendGamepadInput,
        sendKeyInput: sendKeyInput,
        sendMouseInput: sendMouseInput,
        playject: playject, // Do we need to expose direct messaging?
    }
}

export const Playjector = () => {

    const playjector = usePlayjector('pnjigokceldodnojgopgfkmmoejncgpp');

    return <Box sx={{ m: '2rem' }}>
        <Typography>Playjector Testing</Typography>
        <Typography>Extension ID: {playjector.extension}</Typography>
        <Typography>Debug? {playjector.showDebug ? 'true' : 'false'}</Typography>
        <Typography sx={{ mb: '2rem' }}>IsConnected? {playjector.isConnected ? 'true' : 'false'}</Typography>
        <Box>
            <Typography>Playjector</Typography>
            <Button disabled={playjector.isConnected} onClick={() => { playjector.connect(1000) }} variant="contained" sx={{ width: "8rem", mb: "2rem", mr: "1rem" }}>Connect</Button>
            <Button disabled={!playjector.isConnected} onClick={() => { playjector.disconnect() }} variant="contained" sx={{ width: "8rem", mb: "2rem", mr: "1rem" }}>Disconnect</Button>
        </Box>
        <Box>
            <Typography>Heartbeat</Typography>
            <Button disabled={!playjector.isConnected} onClick={() => { playjector.setHeartbeat(playjector.heartbeat ? 0 : 1000) }} variant="contained" sx={{ width: "8rem", mb: "2rem", mr: "1rem" }}>{playjector.heartbeat ? 'Disable' : 'Enable'}</Button>
        </Box>
        <Box>
            <Typography>Data</Typography>
            <Button disabled={!playjector.isConnected} onClick={() => { playjector.test(100) }} variant="contained" sx={{ width: "8rem", mb: "2rem", mr: "1rem" }}>Send Tests</Button>
            <Button disabled={!playjector.isConnected} onClick={() => {
                playjector.sendGamepadInput({
                    userId: 0,
                    timestamp: 10000,
                    xInput: {
                        wButtons: 555,
                        bLeftTrigger: 128,
                        bRightTrigger: 128,
                        sThumbLX: 0,
                        sThumbLY: 0,
                        sThumbRX: 0,
                        sThumbRY: 0,
                    }
                })
            }} variant="contained" sx={{ width: "8rem", mb: "2rem", mr: "1rem" }}>Send Input</Button>
        </Box>
        <Box>
            <Typography>Debug</Typography>
            <Button disabled={!playjector.isConnected} onClick={() => { playjector.debug() }} variant="contained" sx={{ width: "8rem", mb: "2rem", mr: "1rem" }}>Toggle</Button>
        </Box>
    </Box>
}

import { useEffect, useState } from "react";
import { defaultGamepadData, GamepadData, GamepadSvg, useGameInputs } from "./GameInputs"

export const Sample = () => {

    const [data, setData] = useState<GamepadData>(JSON.parse(JSON.stringify(defaultGamepadData)));

    useGameInputs(true, (message) => {
        setData(typeof message === 'object' ? message : JSON.parse(message));
    });

    const downHandler = (k: any) => {
        console.log('down', k);
    }

    const upHandler = (k: any) => {
        console.log('up', k);
    }

    const pressHandler = (k: any) => {
        console.log('press', k);
    }

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        window.addEventListener("keypress", pressHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
            window.removeEventListener("keypress", pressHandler);
        }
    })

    return <>
        <GamepadSvg data={data} colors={{
            active: "#2F80ED",
            inactive: "rgb(200,200,200)"
        }} />
    </>
}
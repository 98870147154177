import { Box, Typography } from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import { readObjectPrototype } from "./utilities";


export const useKeyboard = (callBack: (data: any) => void) => {

    const cb = useRef<(data: any) => void>(callBack);

    const handleKeyDown = useCallback((e) => {
        if (!cb) return;
        e.preventDefault();
        e.stopPropagation();
        cb.current({
            event: 'keyDown',
            data: {
                ...readObjectPrototype(e)
            }
        });
    }, [cb]);

    const handleKeyUp = useCallback((e) => {
        if (!cb) return;
        e.preventDefault();
        e.stopPropagation();
        cb.current({
            event: 'keyUp',
            data: {
                ...readObjectPrototype(e)
            }
        });
    }, [cb]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, { capture: true });
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    });

    useEffect(() => {
        document.addEventListener('keyup', handleKeyUp, { capture: true });
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        }
    });

    return {
        service: 'I am keyboard'
    }
}

interface KeyMessage {
    userId: string,
    tag: number,
    timestamp: number,
    type: string,
    subtype: string,
    tsPlayerEventCaptured: number,
    [key: string]: string | number | {
        keyCode: number
    }
}

export const Keyboard = () => {
    
    const [message, setMessage] = useState<KeyMessage>({
        userId: '',
        tag: 0,
        timestamp: 0,
        type: 'key',
        subtype: 'n/a',
        tsPlayerEventCaptured: 0,
        'waiting': {
            keyCode: 0
        }
    });

    const keyboard = useKeyboard((input: any) => {
        const message = {
            userId: '',
            tag: 0,
            timestamp: 0,
            type: 'key',
            subtype: input.event,
            tsPlayerEventCaptured: Date.now(),
            [input.event]: {
                keyCode: input.data.keyCode
            }
        }
        setMessage(message);
    });

    return <>
        <Box sx={{ m: '2rem' }}>
            <Typography>Keyboard Tests</Typography>
            <Typography>Service: {keyboard.service}</Typography>
        </Box>
        <Box sx={{ m: '2rem' }}>
            <pre>{JSON.stringify(message, null, 4)}</pre>
        </Box>
    </>
}
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"

export interface StreamOptions {
    select: "user" | "display"
}

export const useStream = (options: StreamOptions) => {

    const source = useRef<MediaStream>();
    const devices = useRef<MediaDeviceInfo[]>();
    const [isActive, setIsActive] = useState<boolean>(false);
    const [select, setSelect] = useState<string>(options.select);

    const getStream = () => {
        setIsActive(false);
        if (source.current) {
            // Halt all existing tracks
            source.current.getTracks().forEach(track => track.stop());
            source.current = undefined;
        }
        navigator.mediaDevices.enumerateDevices().then((d) => {
            devices.current = d;
        });
        if (select === "display") {
            navigator.mediaDevices.getDisplayMedia({ video: { frameRate: 60 }, audio: true }).then((stream) => {
                source.current = stream;
                setIsActive(true);
            });
        } else {
            navigator.mediaDevices.getUserMedia({ video: { frameRate: 60 }, audio: true }).then((stream) => {
                source.current = stream;
                setIsActive(true);
            });
        }
    }

    const setStreamSource = (select: string) => {
        setSelect(select);
    }

    return {
        name: 'Stream',
        getStream: getStream,
        setStreamSource: setStreamSource,
        source: source.current,
        devices: devices.current,
        isActive: isActive
    }
}

export const Stream = () => {

    const stream = useStream({ select: "display" });
    const videoPlayer = useRef<HTMLVideoElement>(null);
    const streamSource = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        if (!videoPlayer.current || !stream.isActive) return;
        videoPlayer.current.srcObject = stream.source || null;
        videoPlayer.current.play();
        videoPlayer.current.muted = false;
    });

    const handleChangeStreamSource = (event: SelectChangeEvent) => {
        stream.setStreamSource(event.target.value || 'display');
    }

    return <>
        <Box sx={{ margin: '2rem' }}>
            <Box sx={{ mb: '2rem' }}>
                <Typography>Stream Tests</Typography>
            </Box>
            <Stack direction="row" spacing={2}>
                <Stack>
                    <Box sx={{ mb: '0rem' }}>
                        <Box>
                            <FormControl variant="standard" size="small" sx={{ width: "20rem" }}>
                                <InputLabel>Stream Source:</InputLabel>
                                <Select onChange={handleChangeStreamSource} ref={streamSource} label="Stream Source" defaultValue="display">
                                    <MenuItem value="display">Display Media via Dialog</MenuItem>
                                    <MenuItem value="user">Browser Camera/Mic</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Button onClick={() => { stream.getStream() }} variant="contained" sx={{ width: "8rem", mt: "1rem", mr: "1rem" }}>Get Stream</Button>
                    </Box>
                    <Box sx={{
                        maxHeight: "300px", maxWidth: "450px", "> video": {
                            maxHeight: "300px", maxWidth: "450px",
                            width: '450px',
                            height: '300px',
                        }
                    }}>
                        <video ref={videoPlayer} playsInline autoPlay></video>
                    </Box>
                </Stack>
                <Box>
                    <pre>
                        {stream.source ? JSON.stringify(stream.source?.getTracks().map(t => { return { ...t.getSettings(), kind: t.kind }}), null, 4) : 'No source'}
                    </pre>
                </Box>
                <Box>
                    <pre>
                        {stream.devices ? JSON.stringify(stream.devices, null, 4) : 'No devices'}
                    </pre>
                </Box>
            </Stack>
        </Box>
    </>
}

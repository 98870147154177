import { Box, Button, CardMedia, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { InputOptions, usePingPong } from './PingPong';

interface localProps {
    name: string;
}

export const Host = (props: localProps) => {

    const pingpong = usePingPong();
    const [name, setName] = useState<string>('');
    const [allowTurn, setAllowTurn] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('Not initialized');
    const streamSource = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        setStatus(pingpong.status);
    }, [pingpong.status]);

    const handleChangeStreamSource = (event: SelectChangeEvent) => {
        pingpong.localStream.setStreamSource(event.target.value || 'display');
    }

    const onCheck = (toggle: keyof InputOptions) => {
        pingpong.setInputOptions({
            ...pingpong.inputOptions,
            [toggle]: !pingpong.inputOptions[toggle]
        });
    }

    return <>
        <Grid
            container
            spacing={0}
            direction="row"
            alignItems="top"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item display={{ xs: "none", md: "flex" }} zeroMinWidth={true} xs={0} sm="auto" sx={{ padding: "2rem", backgroundColor: "#111111" }}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    style={{ minHeight: "100vh" }}
                >
                    <Grid item xs={3}>
                        <CardMedia component="img" image={process.env.PUBLIC_URL + "/playcast-gold.png"} alt="PlayCast Demo" sx={{ align: "center", maxHeight: "20vh", maxWidth: "300px", mt: "1rem", mb: "0.25rem" }} />
                    </Grid>
                    <Grid item xs={9} sx={{ textAlign: "center" }}>
                        <Typography >Your Host ID: {name || 'Awaiting ID'}</Typography>
                        <Typography>Player: {pingpong.dataReady ? 'Connected' : 'Waiting'}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={true}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="left"
                    style={{ minHeight: "100vh", padding: '2rem' }}
                >
                    <Grid item xs={12} sx={{ textAlign: "left" }}>
                        <Typography variant="h2">Playcaster</Typography>
                        <Stack sx={{ mb: '2rem' }}>
                            <Typography>Peer Connection</Typography>
                            <Typography>Name: {name || 'None'}</Typography>
                            <Typography>Status: {status}</Typography>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="h4">Setup</Typography>
                            <TextField onChange={e => setName(e.target.value)} sx={{ maxWidth: "30rem" }} label="Set Host ID" variant="outlined" size="small" />
                            <Box>
                                <FormControl variant="outlined" size="small" sx={{ width: "30rem" }}>
                                    <InputLabel>Stream Source:</InputLabel>
                                    <Select onChange={handleChangeStreamSource} ref={streamSource} label="Stream Source" defaultValue="display">
                                        <MenuItem value="display">Display Media via Dialog</MenuItem>
                                        <MenuItem value="user">Browser Camera/Mic</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Stack spacing={1} direction="row">
                                <Button onClick={() => { pingpong.localStream.getStream(); }} variant="contained" sx={{ width: "9rem" }}>Get&nbsp;Stream</Button>
                                <Button onClick={() => { pingpong.setup(name, allowTurn, 'host'); }} variant="contained" sx={{ width: "9rem" }}>Host</Button>
                                <FormControlLabel control={<Checkbox size="small" onChange={e => setAllowTurn(e.target.checked)} />} label="Allow&nbsp;TURN" />
                            </Stack>
                        </Stack>
                        <Stack direction="row" sx={{ mt: "2rem", justifyContent: "space-between" }} spacing={2}>
                            <Box>
                                <Typography variant="h4">Playjector Testing</Typography>
                                <Typography>Extension ID: {pingpong.playjector.extension}</Typography>
                                <Typography>Debug? {pingpong.playjector.showDebug ? 'true' : 'false'}</Typography>
                                <Typography sx={{ mb: '2rem' }}>IsConnected? {pingpong.playjector.isConnected ? 'true' : 'false'}</Typography>
                                <Box>
                                    <Typography>Playjector</Typography>
                                    <Button disabled={pingpong.playjector.isConnected} onClick={() => { pingpong.playjector.connect(1000) }} variant="contained" sx={{ width: "9rem", mb: "2rem", mr: "1rem" }}>Connect</Button>
                                    <Button disabled={!pingpong.playjector.isConnected} onClick={() => { pingpong.playjector.disconnect() }} variant="contained" sx={{ width: "9rem", mb: "2rem", mr: "1rem" }}>Disconnect</Button>
                                </Box>
                                <Box>
                                    <Typography>Heartbeat</Typography>
                                    <Button disabled={!pingpong.playjector.isConnected} onClick={() => { pingpong.playjector.setHeartbeat(pingpong.playjector.heartbeat ? 0 : 1000) }} variant="contained" sx={{ width: "9rem", mb: "2rem", mr: "1rem" }}>{pingpong.playjector.heartbeat ? 'Disable' : 'Enable'}</Button>
                                </Box>
                                <Box>
                                    <Typography>Data</Typography>
                                    <Button disabled={!pingpong.playjector.isConnected} onClick={() => { pingpong.playjector.test(100) }} variant="contained" sx={{ width: "9rem", mb: "2rem", mr: "1rem" }}>Send Tests</Button>
                                    <Button disabled={!pingpong.playjector.isConnected} onClick={() => {
                                        pingpong.playjector.sendGamepadInput({
                                            userId: 0,
                                            timestamp: 10000,
                                            xInput: {
                                                wButtons: 555,
                                                bLeftTrigger: 128,
                                                bRightTrigger: 128,
                                                sThumbLX: 0,
                                                sThumbLY: 0,
                                                sThumbRX: 0,
                                                sThumbRY: 0,
                                            }
                                        })
                                    }} variant="contained" sx={{ width: "9rem", mb: "2rem", mr: "1rem" }}>Send Input</Button>
                                    <Button disabled={!pingpong.playjector.isConnected} onClick={() => {
                                        setTimeout(() => pingpong.playjector.sendKeyInput({
                                            userId: 0,
                                            timestamp: 10000,
                                            event: 'keyDown',
                                            data: {
                                                keyCode: 68
                                            }
                                        }), 3000);
                                    }} variant="contained" sx={{ width: "9rem", mb: "2rem", mr: "1rem" }}>Send Key</Button>
                                </Box>
                                <Box>
                                    <Typography>Toggles</Typography>
                                    <Button disabled={!pingpong.playjector.isConnected} onClick={() => { pingpong.playjector.debug() }} variant="contained" sx={{ width: "9rem", mb: "2rem", mr: "1rem" }}>Debugger</Button>
                                    <Button disabled={!pingpong.playjector.isConnected} onClick={() => { pingpong.playjector.benchmark() }} variant="contained" sx={{ width: "9rem", mb: "2rem", mr: "1rem" }}>Benchmarks</Button>
                                    <Stack spacing={2} direction="row">
                                        <FormControlLabel control={<Checkbox onClick={() => onCheck("gamepad")} checked={pingpong.inputOptions.gamepad} />} label="Gamepad" />
                                        <FormControlLabel control={<Checkbox onClick={() => onCheck("mouse")} checked={pingpong.inputOptions.mouse} />} label="Mouse" />
                                        <FormControlLabel control={<Checkbox onClick={() => onCheck("keyboard")} checked={pingpong.inputOptions.keyboard} />} label="Keyboard" />
                                        <FormControlLabel control={<Checkbox onClick={() => onCheck("touch")} checked={pingpong.inputOptions.touch} />} label="Touch" />
                                        <FormControlLabel control={<Checkbox onClick={() => onCheck("showMouse")} checked={pingpong.inputOptions.showMouse} />} label="Show Mouse" />
                                    </Stack>
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
}

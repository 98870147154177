import env from 'react-dotenv';
import { createId } from '@paralleldrive/cuid2';

export const getRoom = () => {
    // First, use environment
    let room: string = env.ROOM;

    // Consider hostname alternative if no environemnt variable
    if (!room) {
        switch (window.location.host.split(':')[0]) {
            case 'peerclient.pangadev.com':
                room = 'e3a15cbf-4e1c-494a-b288-51305e5eb999';
                break;

            default:
            // I guess not!
        }
    }

    class urlProps extends URLSearchParams {
        room?: string;
    }

    // Allow URL override
    const urlParams = new Proxy(new urlProps(window.location.search), {
        get: (searchParams, prop: keyof urlProps) =>
            searchParams.get(prop as string),
    });

    // Take URL room, or room we figured out above, or a new createId as fallback
    room = urlParams.room || room || createId();

    return room;
};

export const readObjectPrototype = (x: any) => {
    const getters = Object.getPrototypeOf(x);
    const props = Object.keys(getters);

    let output: any = {};
    for (let i = 0, len = props.length; i < len; i++) {
        if (['number', 'string', 'boolean'].includes(typeof x[props[i]])) {
            output[props[i]] = x[props[i]];
        }
    }
    return output;
};

// Translate offsetX and offsetY on a video to x,y on source display
// ox,oy are offsets from edge of target video tag
// tw,th are width and height of target video tag
// vw,vh are width and height of source video
// Accounts for matte areas
export const translateXY = (
    ox: number,
    oy: number,
    tw: number,
    th: number,
    vw: number,
    vh: number
  ): { x: number; y: number } => {
    // Expected dimensions
    const edw = Math.round(th / (vh / vw));
    const edh = Math.round(tw * (vh / vw));
  
    // What we'd expect if tag aspect ratio matches video
    // Subtract 1 from target because size is 1-based, we need 0-based
    let x = Math.round((ox / (tw - 1)) * vw);
    let y = Math.round((oy / (th - 1)) * vh);
  
    // Using -1/+1 so we aren't bothering with matte calculation
    // when we're within a pixel
    if (edh < th) {
      // vertical matte
      const matte = Math.floor((th - edh) / 2);
      if (oy < matte) {
        // In vertical matte area on top
        y = 0;
      } else if (oy > edh + matte) {
        // In vertical matte area on bottom
        y = vh;
      } else {
        // Over the video, set t proportionally
        y = Math.round(((oy - matte) / edh) * vh);
      }
    } else if (edh > th) {
      // horizontal matte
      const matte = Math.floor((tw - edw) / 2);
      if (ox < matte) {
        // In horizontal matte area on left
        x = 0;
      } else if (ox > edw + matte) {
        // In horizontal matte area on right
        x = vw;
      } else {
        // Over the video, set x proportionally
        x = Math.round(((ox - matte) / edw) * vw);
      }
    }
  
    // Switch back to 0-based
    return { x: Math.max(0, x-1), y: Math.max(0, y-1) };
  };
  
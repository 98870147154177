import { Box, Button, Typography } from "@mui/material"
import { useRef, useState } from "react"

interface MouseState {
    x?: number,
    y?: number,
    renderWidth?: number,
    renderHeight?: number
}

export const useCapture = (video: HTMLDivElement | undefined, width: number, height: number) => {

    const [mouseState, setMouseState] = useState<MouseState>({
        x: 0,
        y: 0,
        renderWidth: width,
        renderHeight: height
    });
    
    const handleMouseMove = (e: MouseEvent) => {
        setMouseState({
            ...mouseState,
            x: Math.max(Math.min(e.offsetX, ((mouseState.renderWidth || 0)-1)), 0),
            y: Math.max(Math.min(e.offsetY, ((mouseState.renderHeight || 0)-1)), 0)
        });
    };

    const startCapture = (video: HTMLDivElement) => {
        video!.addEventListener('mousemove', handleMouseMove, { capture: true });
    }

    const setDisplay = (width: number, height: number) => {
        setMouseState({
            ...mouseState,
            renderWidth: width,
            renderHeight: height
        });
    }

    return {
        mouseState: mouseState,
        setDisplay: setDisplay,
        startCapture: startCapture
    }
}

export const Capture = () => {
    const video = useRef<HTMLDivElement>();
    const capture = useCapture(video.current, 920, 540);

    return <Box sx={{ m:"2rem" }}>
        <Typography variant="h3">Capture Experiments</Typography>
        <Box ref={video} sx={{ mt: "2rem", mb: "2rem", height: "540px", width: "920px", backgroundColor: "#333" }}>
            
        </Box>
        <Box sx={{ mt: "2rem", mb: "2rem" }}>
            <Button onClick={() => { capture.startCapture(video.current!) }}>Start</Button>
            <pre>{JSON.stringify(capture.mouseState, null, 4)}</pre>
        </Box>
    </Box>
}

import { Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { readObjectPrototype } from "./utilities";

export const useTouch = () => {
    const [touchEvent, setTouchEvent] = useState({
        event: '',
        location: {
            targetWidth: 2560,
            targetHeight: 1440,
            tsPlayerEventCaptured: 0
        },
        data: {}
    });

    const handleTouchEvent = useCallback((e: TouchEvent) => {

        e.preventDefault();
        console.log(e);

        const target = e.target as HTMLDivElement;
        const rect = target.getBoundingClientRect();
        const data = {
            ...readObjectPrototype(e),
            touches: Object.keys(e.targetTouches).map(x => {
                return {
                    ...readObjectPrototype(e.targetTouches[x as unknown as number]),
                    x: rect.x
                }
            }),
            changedTouches: Object.keys(e.changedTouches).map(x => { return { ...readObjectPrototype(e.changedTouches[x as unknown as number]) } }),
            targetTouches: Object.keys(e.targetTouches).map(x => { return { ...readObjectPrototype(e.targetTouches[x as unknown as number]) } }),
        }

        const location = {
            targetWidth: target.offsetWidth,
            targetHeight: target.offsetHeight,
            tsPlayerEventCaptured: Date.now(),
        }

        const newTouchEvent = {
            event: e.type,
            data: data,
            location: location
        }

        setTouchEvent(() => newTouchEvent);
    }, []);

    const ref = useRef<HTMLDivElement | null>(null);

    const callbackRef = useCallback(
        (node) => {
            if (ref.current) {
                console.log('remove touch listeners');
                ref.current.removeEventListener("touchstart", handleTouchEvent);
                ref.current.removeEventListener("touchend", handleTouchEvent);
                ref.current.removeEventListener("touchmove", handleTouchEvent);
                ref.current.removeEventListener("touchcancel", handleTouchEvent);
            }

            ref.current = node;

            if (ref.current) {
                console.log('adding touch listeners');
                ref.current.addEventListener("touchstart", handleTouchEvent);
                ref.current.addEventListener("touchstart", handleTouchEvent);
                ref.current.addEventListener("touchstart", handleTouchEvent);
                ref.current.addEventListener("touchstart", handleTouchEvent);
            }
        },
        [handleTouchEvent]
    );

    return { ref: ref, cb: callbackRef, touchEvent };
};

export const Touch = () => {
    const { cb, touchEvent } = useTouch();
    const [debug, setDebug] = useState<boolean>(true);

    return <>
        <Box sx={{ m: "2rem" }}>
            <Typography variant="h3">Touch Experiments</Typography>
            <Stack direction="row">
                <Box sx={{
                    maxHeight: "500px",
                    maxWidth: "900px",
                    backgroundColor: "#333",
                    "> div > div": {
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: 'contain',
                        width: '900px',
                        height: '500px'
                    }
                }}>
                    <Box ref={cb} ><Box></Box></Box>
                    <Button onClick={() => { setDebug((prev) => !prev) }}>Debug</Button>
                </Box>
                {debug ? <Box sx={{ mt: "2rem", mb: "2rem" }}>
                    <pre>{JSON.stringify(touchEvent, null, 4)}</pre>
                </Box> : ``}
            </Stack>
        </Box>
    </>
}

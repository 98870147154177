import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { readObjectPrototype } from "./utilities";

export const Location = () => {
    const added = useRef<boolean>(false);
    const [xy, setXy] = useState<{ x: number, y: number, event: unknown, calc: unknown }>(
        { x: 200, y: 200, event: {}, calc: {} }
    );

    const handleMouseEvent = (e: MouseEvent) => {
        const scale = Math.round(((e.screenX - window.screenX) / e.clientX) * 100);
        setXy((prev) => {
            return {
                x: e.offsetX,
                y: e.offsetY,
                event: { ...readObjectPrototype(e) },
                calc: {
                    windowScreenX: window.screenX,
                    eventScreenX: e.screenX,
                    eventClientX: e.clientX,
                    windowScreenY: window.screenY,
                    eventScreenY: e.screenY,
                    eventClientY: e.clientY,
                    windowInnerHeight: window.innerHeight,
                    windowOuterHeight: window.outerHeight,
                    topBarHeight: Math.round(window.outerHeight - ((window.innerHeight * scale) / 100)),
                    scale: scale
                }
            }
        });
        e.stopImmediatePropagation();
    }

    useEffect(() => {
        const element = document.getElementById('everything');
        if (!added.current && element) {
            element.addEventListener("mousemove", handleMouseEvent);
            added.current = true;
        }
    }, []);

    return <>
        <Box sx={{
            backgroundColor: "red",
            position: "absolute",
            top: "1000px", // xy.y.toString().concat("px"),
            left: "1000px", // xy.x.toString().concat("px"),
            width: "2px",
            height: "2px",
            pointerEvents: "none"
        }}></Box>
        <Box id="everything" sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100vw",
            height: "100vh",
            zIndex: "100"
        }}>
            <Box sx={{ p: "1rem", pointerEvents: "none" }}>
                <h1>Location</h1>
                <Box sx={{ pointerEvents: "none", fontFamily: "monospace", fontSize: "0.75rem", whiteSpace: "pre-wrap" }} >
                    {JSON.stringify(xy, null, 4)}
                </Box>
            </Box>
        </Box>
    </>;
}
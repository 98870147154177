import { Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { readObjectPrototype } from "./utilities";

export const useMouse = () => {

    const [mouseEvent, setMouseEvent] = useState({
        event: '',
        location: {
            x: 0,
            y: 0,
            targetWidth: 2560,
            targetHeight: 1440,
        },
        data: {}
    });

    const handleMouseEvent = useCallback((e: MouseEvent) => {
        
        const target = e.target as HTMLVideoElement;
        switch (e.type) {
            case 'mousemove':

                setMouseEvent((prevState) => {
                    return {
                        event: "mouseLocation",
                        location: {
                            ...prevState.location,
                            targetWidth: target.offsetWidth,
                            targetHeight: target.offsetHeight,
                            x: Math.max(Math.min(e.offsetX, ((prevState.location.targetWidth || 0) - 1)), 0),
                            y: Math.max(Math.min(e.offsetY, ((prevState.location.targetHeight || 0) - 1)), 0)
                        },
                        data: { ...readObjectPrototype(e) }
                    };
                });
                
                break;
            
            case 'mousedown':
                // console.log('mousedown');
                setMouseEvent((prevState) => {
                    return {
                        event: "mouseDown", location: {
                            ...prevState.location,
                            targetWidth: target.offsetWidth,
                            targetHeight: target.offsetHeight,
                            x: Math.max(Math.min(e.offsetX, ((prevState.location.targetWidth || 0) - 1)), 0),
                            y: Math.max(Math.min(e.offsetY, ((prevState.location.targetHeight || 0) - 1)), 0)
                        }, data: { ...readObjectPrototype(e) }
                    }
                });
                break;

            case 'mouseup':
                // console.log('mouseup');
                setMouseEvent((prevState) => {
                    return {
                        event: "mouseUp", location: {
                            ...prevState.location,
                            targetWidth: target.offsetWidth,
                            targetHeight: target.offsetHeight,
                            x: Math.max(Math.min(e.offsetX, ((prevState.location.targetWidth || 0) - 1)), 0),
                            y: Math.max(Math.min(e.offsetY, ((prevState.location.targetHeight || 0) - 1)), 0)
                        }, data: { ...readObjectPrototype(e) }
                    }
                });
                break;
        }
    }, []);

    const ref = useRef<HTMLVideoElement | null>(null);

    const callbackRef = useCallback(
        (node) => {
            if (ref.current) {
                ref.current.removeEventListener("mousemove", handleMouseEvent);
                ref.current.removeEventListener("mouseup", handleMouseEvent);
                ref.current.removeEventListener("mousedown", handleMouseEvent);
            }

            ref.current = node;

            if (ref.current) {
                ref.current.addEventListener("mousemove", handleMouseEvent);
                ref.current.addEventListener("mouseup", handleMouseEvent);
                ref.current.addEventListener("mousedown", handleMouseEvent);
            }
        },
        [handleMouseEvent]
    );

    return { ref: ref, cb: callbackRef, mouseEvent };
};

export const Mouse = () => {
    const { cb, mouseEvent } = useMouse();
    const [dotLocation, setDotLocation] = useState({ x: 0, y: 0 });
    const [debug, setDebug] = useState<boolean>(true);

    useEffect(() => {
        const newX = mouseEvent.location.x * (window.innerWidth / mouseEvent.location.targetWidth);
        const newY = mouseEvent.location.y * (window.innerHeight / mouseEvent.location.targetHeight);
        setDotLocation({ x: newX, y: newY });
    }, [mouseEvent]);

    return <>
        <Box sx={{ height:"3px", width:"3px", backgroundColor:"#ff0000", position:"absolute", left:``+dotLocation.x+'px', top:``+dotLocation.y+'px' }}></Box>
        <Box sx={{ m: "2rem" }}>
            <Typography variant="h3">Mouse Experiments</Typography>
            <Stack direction="row">
                <Box sx={{
                    maxHeight: "500px",
                    maxWidth: "900px",
                    backgroundColor: "#333",
                    "> video": {
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: 'contain',
                        width: '900px',
                        height: '500px'
                    }
                }}>
                    <video ref={cb} playsInline autoPlay></video>
                    <Button onClick={() => { setDebug((prev) => !prev) }}>Debug</Button>
                </Box>
                {debug ? <Box sx={{ mt: "2rem", mb: "2rem" }}>
                    <pre>{JSON.stringify(mouseEvent, null, 4)}</pre>
                </Box> : ``}
            </Stack>
    </Box>
    </>
}

import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { Player } from './Player';
import { Playjector } from './Playjector';
import { Host } from './Host';
import { Routes } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Participant } from './Participant';
import { Conference } from './Conference';
import './App.css';
import { Home } from './Home';
import './'
import { ControllerMonitor } from './ControllerMonitor';
import { Sample } from './Sample';
import { Stream } from './Stream';
import { Playcaster } from './Playcaster';
import { Keyboard } from './Keyboard';
import { Receiver } from './Receiver';
import { PeerTest } from './PeerTest';
import { Mouse } from './Mouse';
import { Location } from './Location';
import { Mouse as MouseBefore } from './MouseBefore';
import { Capture } from './Capture';
import { PingPong } from './PingPong';
import { Touch } from './Touch';
import { Gamepads } from './Gamepads';
import { Message } from './Message';

function App() {

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);
  
  return (<ThemeProvider theme={theme}><Router><CssBaseline />
    <Routes>
      <Route path="/host" element={<Host name="PlayCaster Host" />} />
      <Route path="/host/:ext" element={<Host name="PlayCaster Host" />} />
      <Route path="/alex" element={<Host name="Alex" />} />
      <Route path="/trey" element={<Host name="Trey" />} />
      <Route path="/monitor" element={<ControllerMonitor />} />
      <Route path="/sample" element={<Sample />} />
      <Route path="/playjector" element={<Playjector />} />
      <Route path="/connection" element={<Playcaster />} />
      <Route path="/player" element={<Player />} />
      <Route path="/receiver" element={<Receiver />} />
      <Route path="/capture" element={<Capture />} />
      <Route path="/peertest" element={<PeerTest />} />
      <Route path="/pingpong/:who" element={<PingPong />} />
      <Route path="/stream" element={<Stream />} />
      <Route path="/keyboard" element={<Keyboard />} />
      <Route path="/mouse" element={<Mouse />} />
      <Route path="/location" element={<Location />} />
      <Route path="/mouse/prior" element={<MouseBefore />} />
      <Route path="/gamepads" element={<Gamepads />} />
      <Route path="/touch" element={<Touch />} />
      <Route path="/message" element={<Message />} />
      <Route path="/player/:init" element={<Player />} />
      <Route path="/join" element={<Participant />} />
      <Route path="/conference" element={<Conference />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </Router></ThemeProvider>
  );
}

export default App;

import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import Peer from 'peerjs';
import { useEffect, useRef, useState } from 'react';
import { createId } from '@paralleldrive/cuid2';
import { getRoom } from './utilities';

interface localProps {
}

export const Participant = (props: localProps) => {

    const videoGrid = useRef<HTMLDivElement>(null);

    const [selfVideo] = useState<HTMLVideoElement>(Object.assign(document.createElement('video'), { defaultMuted: true, autoplay: true }));
    const [host] = useState<string>(getRoom());
    const [connected, setConnected] = useState<boolean>(false);
    const [peer, setPeer] = useState<Peer | null>();
    const [connection, setConnection] = useState<Peer.DataConnection | null>();
    const [peerId, setPeerId] = useState<string>('Awaiting ID');

    const addVideoStream = (video: HTMLVideoElement, stream: MediaStream, muted: boolean) => {
        if (!videoGrid.current) return;
        if (video.srcObject) return; // If we already have a stream going, stop that

        // Create a container div for sizing
        const wrapper = document.createElement('div');
        wrapper.className = "videoBox";
        video.srcObject = stream;
        video.className = "videoPlayer";
        video.addEventListener('loadedmetadata', () => { // Play the video as it loads
            video.muted = muted;
            video.play();
        });
        wrapper.append(video);
        videoGrid.current.append(wrapper); // Append video element to videoGrid
    }

    navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
    }).then(stream => {
        // Show our own video in the conference, muted to not get feedback
        addVideoStream(selfVideo, stream, true);
    });

    useEffect(() => {
        if (!peer) {
            const id = createId();
            const newPeer = new Peer(id, {
                host: 'peerserver.pangadev.com',
                port: 443,
                secure: true,
                debug: 0,
                config: { 'iceServers': [{ 'urls': ['stun:stun.l.google.com:19302'] }] }
            });

            newPeer.on('open', id => {
                setPeerId(id);
            });

            newPeer.on('call', (call) => {
                console.log('got a call', call);
                call.answer(selfVideo!.srcObject as MediaStream);
                const remoteVideo = document.createElement('video');
                call.on('stream', (remoteStream) => {
                    addVideoStream(remoteVideo, remoteStream, false);
                });
            });

            newPeer.on('error', (e) => {
                console.log(e);
            });
            setPeer(newPeer);
        }
    }, [peer, connection, peerId, selfVideo]);

    const connect = () => {
        if (!peer || !peerId) return;
        const c = peer.connect(host);
        c.on('error', (e) => console.log('ERROR', e));
        c.on('data', (data) => {
            console.log(data);
            const payload = JSON.parse(data);
            if (payload?.action === 'join' && payload?.id !== peerId) {
                // A new user has joined the room, let's call
                const newUser = peer.call(payload.id, selfVideo!.srcObject as MediaStream);
                const remoteVideo = document.createElement('video');
                newUser.on("stream", (remoteStream) => {
                    addVideoStream(remoteVideo, remoteStream, false);
                });
                newUser.on("disconnected", () => remoteVideo.remove());
            }
            console.log(payload);
        });
        c.on('close', () => console.log('connection closed'));
        c.on('open', () => {
            console.log(c);

            setConnected(true);
            setConnection(c);

            c.send(JSON.stringify({ action: 'join', id: peerId }));
        });
    }

    return <Card elevation={0}>
        <CardContent>
            <Typography sx={{
                position: "absolute",
                bottom: "0.25rem",
                right: "0.25rem",
                fontSize: "0.75rem",
                color: "white"
            }}>ID: {peerId}</Typography>
            {!host || !connected ? <Box>
                <Button onClick={connect} variant="contained" sx={{
                    position: "absolute",
                    top: "0",
                    right: "0.25rem"
                }}>Join Video Chat</Button>
            </Box> : ''}

            <Box ref={videoGrid} sx={{ display: "flex", maxHeight: "90vh" }}>
            </Box>
        </CardContent>
    </Card>
}

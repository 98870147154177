import { Box, Button, Stack, TextField, Typography } from "@mui/material"
import { useRef } from "react";
import Peer from 'peerjs';
import { config } from './config';

interface Connections {
    [name: string]: Peer.DataConnection
}

export const usePeerTest = () => {

    const connections = useRef<Connections>({});

    const getNewPeer = (id: string, config: Peer.PeerJSOption) => {
        const p = new Peer(id, config);
        p.on('open', (id: string) => {
            console.log(id + ' is open');
        });
            
        p.on('connection', (conn: Peer.DataConnection) => {
            console.log('New connection');
            conn.on('data', (data:string) => {
                console.log(conn.label+' from '+conn.peer, 'data', data);
            });

            conn.on('error', (error) => {
                console.log(conn.peer + ' error', error);
            });

            conn.on('open', () => {
                conn.send('hello');
                console.log(conn.peer + ' open');
            });

            //connections.current[conn.peer] = conn;
        });

        return p;
    }

    const alice = useRef<Peer>();
    const bob = useRef<Peer>();

    const setup = () => {
        alice.current = getNewPeer('alice', config.peerServer);
        bob.current = getNewPeer('bob', config.peerServer);
    }
    
    const connect = () => {
        const x = alice.current!.connect('bob');
        connections.current['AliceToBob'] = x;
    }
    
    const send = (message: string) => {
        connections.current['AliceToBob'].send(message);
    }

    return {
        connect, setup, send
    }

}

export const PeerTest = () => {

    const receiver = usePeerTest();
    const message = useRef<HTMLInputElement>(null);

    return <>
        <Box sx={{ m: '2rem' }}>
            <Box sx={{ mb: '2rem' }}>
                <Typography>Peer Testing</Typography>
                <Typography>Message: {message.current?.value}</Typography>
            </Box>
            <Box sx={{ mb: '2rem' }}>
                <Stack>
                    <TextField inputRef={message} label="Message" />
                </Stack>
            </Box>
            <Box sx={{ mb: '2rem' }}>
                <Stack>
                <Button variant="contained" sx={{ width: "8rem", mt: "1rem", mr: "1rem" }} onClick={() => receiver.setup()}>Setup</Button>
                <Button variant="contained" sx={{ width: "8rem", mt: "1rem", mr: "1rem" }} onClick={() => receiver.connect()}>Connect</Button>
                <Button variant="contained" sx={{ width: "8rem", mt: "1rem", mr: "1rem" }} onClick={() => receiver.send('thing')}>Send</Button>
                    
                </Stack>
            </Box>
            <Box sx={{ mb: '2rem' }}>
            </Box>
        </Box>
    </>
}

import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react";
import Peer from 'peerjs';
import { config } from './config';
import { useParams } from "react-router-dom";
import { createId } from '@paralleldrive/cuid2';
import { useStream } from "./Stream";
import { usePlayjector } from "./Playjector";
import axios from 'axios';

export type InputOptions = {
    gamepad: boolean,
    keyboard: boolean,
    mouse: boolean,
    touch: boolean,
    showMouse: boolean
}

const getName = () => {
    const storedId = localStorage.getItem('peerId');
    const id = storedId || createId();
    localStorage.setItem('peerId', id);
    return id;
}

export const usePingPong = () => {

    const { ext, who } = useParams();

    const self = useRef<Peer>();
    const role = useRef<string>('player');
    const callbackKey = useRef<string>(getName());
    const link = useRef<Peer.DataConnection>();
    const mediaLink = useRef<Peer.MediaConnection>();
    const localStream = useStream({ select: "display" });
    const [remoteStream, setRemoteStream] = useState<MediaStream>();
    const [status, setStatus] = useState<string>('Not initialized');
    const [result, setResult] = useState<string>('');
    const [setupComplete, setSetupComplete] = useState<boolean>(false);
    const [dataReady, setDataReady] = useState<boolean>(false);
    const [mediaReady, setMediaReady] = useState<boolean>(false);
    const playjector = usePlayjector(ext || 'ekidfnlddeibcjlokcabcckicbcjicni');
    const deviceId = useRef<string>('');
    const trackSettings = useRef<any>({});

    const [inputOptions, setInputOptions] = useState<InputOptions>({
        gamepad: true,
        keyboard: true,
        mouse: true,
        touch: false,
        showMouse: false
    });

    useEffect(() => {
        if (!localStream.isActive || role.current !== 'host' || !dataReady) return;
        mediaLink.current?.close();
        mediaLink.current = undefined;
        mediaLink.current = self.current!.call(link.current!.peer, localStream.source!);
        mediaLink.current.on('close', () => {
            console.log('lost medialink');
        });
        deviceId.current = localStream.source?.getVideoTracks()[0].getSettings().deviceId || '';
        trackSettings.current = localStream.source?.getTracks().map(t => { return { ...t.getSettings(), kind: t.kind } });
    }, [localStream.isActive, localStream.source, dataReady]);

    const processData = (data: string | Object) => {
        if (!['host', 'player'].includes(who || '')) {
            if (data === 'RDY') {
                setDataReady(true);
                if (who === 'host') link.current!.send('RDY');
                setResult((who || '<who not set>').concat(' confirms connection is ready'));
            } else if (typeof data === 'string' && data.slice(0, 2) === 'IO') {
                const x = data.split('.');
                setInputOptions(JSON.parse(x[1]));
            } else {
                try {
                    // console.log(data);
                    const d = JSON.parse(data as string);
                    const tsHostReceivedFromPlayer = Date.now();
                    if ('xInput' in d) {
                        const input = {
                            userId: d.id,
                            timestamp: d.timestamp,
                            tsPlayerEventCaptured: d.data?.tsPlayerEventCaptured || 0,
                            tsPlayerSentToHost: d.data?.tsPlayerSentToHost || 0,
                            tsHostReceivedFromPlayer: tsHostReceivedFromPlayer,
                            deviceId: deviceId.current,
                            sourceSettings: trackSettings.current,
                            xInput: d.xInput
                        }
                        playjector.sendGamepadInput(input);
                    } else {
                        if ('event' in d) {
                            switch (d.event) {
                                case 'keyUp':
                                case 'keyDown': {
                                    const input = {
                                        userId: 0,
                                        timestamp: 0,
                                        tsPlayerEventCaptured: d.data?.tsPlayerEventCaptured || 0,
                                        tsPlayerSentToHost: d.data?.tsPlayerSentToHost || 0,
                                        tsHostReceivedFromPlayer: tsHostReceivedFromPlayer,
                                        deviceId: deviceId.current,
                                        sourceSettings: trackSettings.current,
                                        event: d.event,
                                        data: d.data
                                    }
                                    // setKeyHistory(p => {
                                    //     const s = (p.length > 50 ? 1 : 0);
                                    //     return p.slice(s).concat(input);
                                    // });
                                    // console.log('sending to playjector', input);
                                    playjector.sendKeyInput(input);
                                }
                                    break;

                                case 'mouseWheel':
                                case 'mouseDown':
                                case 'mouseUp':
                                case 'mouseClick':
                                case 'mouseDblClick':
                                case 'mouseLocation': {
                                    const input = {
                                        userId: 0,
                                        timestamp: 0,
                                        tsPlayerEventCaptured: d.data?.tsPlayerEventCaptured || 0,
                                        tsPlayerSentToHost: d.data?.tsPlayerSentToHost || 0,
                                        tsHostReceivedFromPlayer: tsHostReceivedFromPlayer,
                                        deviceId: deviceId.current,
                                        sourceSettings: trackSettings.current,
                                        event: d.event,
                                        data: d.data
                                    }
                                    playjector.sendMouseInput(input);
                                }
                                    break;

                                case 'mouseMove': {
                                    // if (!doSend.current) return;
                                    // doSend.current = !doSend.current
                                    const input = {
                                        userId: 0,
                                        timestamp: 0,
                                        tsPlayerEventCaptured: d.data?.tsPlayerEventCaptured || 0,
                                        tsPlayerSentToHost: d.data?.tsPlayerSentToHost || 0,
                                        tsHostReceivedFromPlayer: tsHostReceivedFromPlayer,
                                        deviceId: deviceId.current,
                                        sourceSettings: trackSettings.current,
                                        event: d.event,
                                        data: d.data
                                    }
                                    playjector.sendMouseInput(input);
                                }
                                    break;
                            }
                        }
                    }
                } catch (e) {
                    console.log(e);
                    console.log('unable to parse');
                }
            }
        } else {
            console.log('There is no spoon, I mean who');
            if (typeof (data) === 'object') {
                console.log(data);
                return;
            }
            const x = data.split('.');
            switch (x[0]) {
                case 'RDY':
                    setDataReady(true);
                    setResult('Host confirms connection is ready');
                    link.current!.send('RDY');
                    break;

                case 'IO':
                    setInputOptions(JSON.parse(x[1]));
                    break;

                case 'SS':
                    setResult(`One-way send, clock sync required: ` + (Date.now() - parseInt(x[1])) + 'ms');
                    break;

                case 'PP':
                    setResult('Received ping pong request, replying');
                    link.current!.send('PR.' + x[1]);
                    break;

                case 'PR':
                    setResult('Round trip, same clock: ' + (Date.now() - parseInt(x[1])) + 'ms');
                    break;

                case '1R':
                    const r = parseInt(x[2]);
                    if (r === 0) {
                        setResult('1K bounce, same clock: ' + (Date.now() - parseInt(x[1])) + 'ms');
                    } else {
                        link.current!.send('1K.' + x[1] + '.' + (r - 1));
                    }
                    break;

                case '1K':
                    link.current!.send(['1R', x[1], x[2]].join('.'));
                    break;

                case 'FL':
                    // throw these away
                    break;

                case 'QR':
                    const url = 'https://customsearch.googleapis.com/customsearch/v1?key=AIzaSyBJkDFHt4TqBbozx6gWgWu_u6XQP3JQJuE&cx=704e475edb2c84646&q='.concat(data.slice(3));
                    setResult('Query: '.concat(url));

                    axios.get(url).then(r => {
                        link.current!.send('QX.' + JSON.stringify(r.data));
                    });

                    break;
                
                case 'QX':
                    const result = JSON.parse(data.slice(3));
                    console.log(result);
                    break;

                case 'FX':
                    // Final message in flood
                    link.current!.send('FR.' + x[1]);
                    break;

                case 'FR':
                    // Received final flood response
                    setResult('Flood, same clock: ' + (Date.now() - parseInt(x[1])) + 'ms');
                    break;

                default:
                    console.log(x[0]);
            }
        }
    }

    const getNewPeer = (id: string, config: Peer.PeerJSOption) => {
        const p = new Peer(id, config);
        p.on('open', (id: string) => {
            // console.log(id + ' is open for connections');
        });

        // Used only by player when receiving a new MediaStream
        p.on('call', (call) => {
            console.log('Got media call');
            call.answer();
            call.on('stream', (stream) => {
                console.log('stream updated');
                setRemoteStream(stream);
                setMediaReady(true);
            });
            call.on('close', () => {
                setRemoteStream(undefined);
            });
        });

        p.on('error', (error) => {
            console.log('Peer error', error);
        });

        // This connection is set up to be the receiver of incoming calls
        p.on('connection', (conn: Peer.DataConnection) => {
            setDataReady(false);
            link.current = conn;

            conn.on('error', (error) => {
                console.log(conn.peer + ' error', error);
            });

            conn.on('open', async () => {

                // Figure out what kind of incoming connection this is
                // type: host | relay    // "host" is STUN, "relay" is TURN
                // role: host | player
                let type = '';
                const report = await link.current?.peerConnection.getStats();
                if (report) {
                    let selected;
                    for (const { type, state, localCandidateId } of report.values()) {
                        if (type === 'candidate-pair' && state === 'succeeded' && localCandidateId) {
                            selected = localCandidateId;
                            break;
                        }
                    }
                    type = report.get(selected).candidateType;
                }

                if (role.current === 'host' && type === 'relay') {
                    // A Player has connected via relay (TURN)
                    // We want to hang up this connection and call back
                    // hopefully to get direct (STUN)
                    console.log('Received connection from player, but via relay, doing callback');

                    const playerKey = link.current!.metadata.callbackKey;
                    const playerId = link.current!.peer;

                    link.current!.close();
                    setDataReady(false);
                    connect(playerId, playerKey);
                } else if (role.current === 'player') {
                    // A Host has done a callback because we had relay
                    // Trying reverse call to get STUN
                    // Confirm the callbackKey before accepting
                    setDataReady(false);
                    if (link.current!.metadata.callbackKey !== callbackKey.current) {
                        // Incorrect callbackKey, reject
                        link.current!.close();
                    } else {
                        // All good, allow data connections now
                        setStatus('Connection ready for data, ' + (type === 'relay' ? 'but using TURN' : 'using STUN'));
                        setDataReady(true);
                        link.current!.on('data', processData);
                    }
                } else {
                    // This is the Host and we already have direct connection
                    link.current!.on('data', processData);
                    send('RDY');
                    setDataReady(true);
                    setStatus('Connection ready for data, using STUN');
                }
            });
        });

        return p;
    }

    const setup = (name: string, allowTurn: boolean, who: string) => {
        // Start local peer
        role.current = who;
        self.current = getNewPeer(name, allowTurn ? config.peerServer : config.noTurn);
        setStatus('Peer created with name "' + name + '"');
        setSetupComplete(true);
    }

    // This uses the current "self" connection to make a connection to a remote
    // Either a Player initiating original contact or a Host doing a return
    // call after detecting the Player connected using relay
    const connect = (remote: string, key?: string) => {
        link.current = self.current!.connect(remote, {
            metadata: {
                // Send the input key (Host returning call)
                // or own callbackKey (Player initiating)
                callbackKey: key || callbackKey.current,
                role: role.current
            }
        });

        link.current.on('error', (error) => {
            console.log(link.current + ' error', error);
        });

        link.current.on('open', async () => {
            setStatus('Initiated new connection with ' + remote);
            link.current!.on('data', processData);
        });
    }

    const send = useCallback((message: string | Object | any) => {
        if (!link.current) return;
        // console.log(message);
        switch (message) {
            case 'RDY':
                link.current.send('RDY');
                break;

            case 'SS':
                link.current.send('SS.' + Date.now());
                break;

            case 'PP':
                link.current.send('PP.' + Date.now());
                break;

            case '1K':
                link.current.send('1K.' + Date.now() + '.1000');
                break;

            case 'QR':
                link.current.send('QR.spider');
                break;

            case 'FL':
                // Timstamp before starting message flood
                const start = Date.now();
                for (let i = 0; i < 999; i++) {
                    link.current.send('FL');
                }
                // Send one last message with "we're done"
                link.current.send('FX.' + start);
                break;

            default:
                // console.log('sending via link');
                if (typeof (message) === 'object' && message && message.data) message.data.tsPlayerSentToHost = Date.now();
                link.current.send(JSON.stringify(message));
        }
    }, []);

    const updateInputOptions = (options: InputOptions) => {
        if (!link.current) return;
        setInputOptions(options);
        link.current.send('IO.'.concat(JSON.stringify(options)));
    }

    return {
        status,
        result,
        setup,
        setupComplete,
        connect,
        dataReady,
        mediaReady,
        send,
        localStream,
        remoteStream,
        playjector,
        inputOptions,
        setInputOptions: updateInputOptions
    }
}

export const PingPong = () => {

    const { who } = useParams();

    const pingpong = usePingPong();
    const [status, setStatus] = useState<string>('Not initialized');
    const [name, setName] = useState<string>('');
    const [remote, setRemote] = useState<string>('lovecraft');
    const [allowTurn, setAllowTurn] = useState<boolean>(false);
    const videoPlayer = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        setStatus(pingpong.status);
    }, [pingpong.status]);

    useEffect(() => {
        if (who !== 'host' || !pingpong.localStream.isActive || !videoPlayer.current) return;
        videoPlayer.current.srcObject = pingpong.localStream.source || null;
        videoPlayer.current.play();
        videoPlayer.current.muted = false;
    }, [pingpong.localStream, who]);

    useEffect(() => {
        if (who !== 'player' || !videoPlayer.current) return;
        if (!pingpong.remoteStream) return;
        videoPlayer.current.srcObject = pingpong.remoteStream || null;
        videoPlayer.current.play();
        videoPlayer.current.muted = false;
    }, [pingpong.remoteStream, who]);

    const onCheck = (toggle: keyof InputOptions) => {
        pingpong.setInputOptions({
            ...pingpong.inputOptions,
            [toggle]: !pingpong.inputOptions[toggle]
        });
    }

    return <>
        <Stack spacing={6} sx={{ p: "1rem" }}>
            <Box>
                <Typography variant="h3">Ping Pong Test</Typography>
                <Typography sx={{ mt: "1rem" }}>Status: {status}</Typography>
                <Typography>You are the {(who === 'player') ? 'Player' : 'Host'}</Typography>
            </Box>
            <Box>
                <Stack spacing={2}>
                    <Typography variant="h5">Local Setup</Typography>
                    <TextField onChange={e => setName(e.target.value)} sx={{ maxWidth: "30rem" }} label="Your Name" helperText="You set this, make it unique, then click Setup" variant="outlined" size="small" />
                    <Stack spacing={8} direction="row">
                        <Stack spacing={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox onChange={e => setAllowTurn(e.target.checked)} />} label="Allow TURN" />
                            </FormGroup>
                            <Button variant="contained" sx={{ width: "9rem", mr: "1rem" }} onClick={() => { pingpong.setup(name, allowTurn, who || 'player'); }}>Setup</Button>
                            {who === 'host' ? <Button onClick={() => { pingpong.localStream.getStream(); }} variant="contained" sx={{ width: "9rem" }}>Get&nbsp;Stream</Button> : ``}
                        </Stack>
                        {who === 'host' ? <Box sx={{
                            textAlign: "center", maxHeight: "180px", maxWidth: "270px", "> video": {
                                maxHeight: "100%", maxWidth: "100%", objectFit: 'contain',

                            }
                        }}><video ref={videoPlayer} playsInline autoPlay></video></Box> : ``}
                        {who === 'host' ? <Box><pre>{JSON.stringify(pingpong.inputOptions, null, 4)}</pre></Box> : ''}
                    </Stack>
                </Stack>
            </Box>
            <Box>
                <Stack spacing={2}>
                    <Typography variant="h5">Remote Connection</Typography>
                    {(who === 'player') ? <>
                        <TextField defaultValue="lovecraft" onChange={e => setRemote(e.target.value)} sx={{ maxWidth: "30rem" }} label="Host Name" helperText="Get this from the host, then click Connect" variant="outlined" size="small" />
                        <Stack spacing={8} direction="row">
                            <Box>
                                <Button variant="contained" sx={{ width: "9rem", mr: "1rem" }} onClick={() => { pingpong.connect(remote); }}>Connect</Button>
                            </Box>
                            <Box sx={{
                                border: "1px solid #333", textAlign: "center", maxHeight: "180px", maxWidth: "270px", "> video": {
                                    maxHeight: "100%", maxWidth: "100%", objectFit: 'contain',
                                }
                            }}><video ref={videoPlayer} playsInline autoPlay></video></Box>
                        </Stack>
                    </> : <Typography>Connections are initiated by the player.</Typography>}
                </Stack>
            </Box>
            <Box>
                <Stack spacing={2}>
                    <Typography variant="h5">Communciation</Typography>
                    <Box>
                        <Button variant="contained" sx={{ width: "9rem", mr: "1rem" }} onClick={() => { pingpong.send('SS'); }}>Simple Send</Button>
                        <Button variant="contained" sx={{ width: "9rem", mr: "1rem" }} onClick={() => { pingpong.send('PP'); }}>Ping Pong</Button>
                        <Button variant="contained" sx={{ width: "9rem", mr: "1rem" }} onClick={() => { pingpong.send('1K'); }}>1K Bounce</Button>
                        <Button variant="contained" sx={{ width: "9rem", mr: "1rem" }} onClick={() => { pingpong.send('FL'); }}>Flood</Button>
                        <Button variant="contained" sx={{ width: "9rem", mr: "1rem" }} onClick={() => { pingpong.send('QR'); }}>Query</Button>
                    </Box>
                    <Stack spacing={2} direction="row">
                        <FormControlLabel control={<Checkbox onClick={() => onCheck("gamepad")} checked={pingpong.inputOptions.gamepad} />} label="Gamepad" />
                        <FormControlLabel control={<Checkbox onClick={() => onCheck("mouse")} checked={pingpong.inputOptions.mouse} />} label="Mouse" />
                        <FormControlLabel control={<Checkbox onClick={() => onCheck("keyboard")} checked={pingpong.inputOptions.keyboard} />} label="Keyboard" />
                        <FormControlLabel control={<Checkbox onClick={() => onCheck("touch")} checked={pingpong.inputOptions.touch} />} label="Touch" />
                        <FormControlLabel control={<Checkbox onClick={() => onCheck("showMouse")} checked={pingpong.inputOptions.showMouse} />} label="Show Mouse" />
                    </Stack>
                    <pre>{pingpong.result}</pre>
                </Stack>
            </Box>
        </Stack>
    </>
}

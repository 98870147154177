import { PeerJSOption } from "peerjs";

export interface configuration {
    peerServer: PeerJSOption,
    noTurn: PeerJSOption
}

export const config: configuration = {
    peerServer: {
        // host: '0.peerjs.com', // use for testing when our peerserver seems broken
        host: 'peerserver.playcast.io',
        port: 443,
        secure: true,
        debug: 0,
        config: {
            iceServers: [
                {
                    urls: 'stun:stun.pangadev.com:5349',
                    username: 'stunadmin',
                    credential: 'm7te8VLHjDi4sA6KTG8g',
                },
                {
                    urls: 'turn:turn.pangadev.com:5349',
                    username: 'stunadmin',
                    credential: 'm7te8VLHjDi4sA6KTG8g',
                },
                // { urls: 'stun:stun.l.google.com:19302' },
            ],
        },
    },
    noTurn: {
        host: 'peerserver.playcast.io',
        port: 443,
        secure: true,
        debug: 0,
        config: {
            iceServers: [
                {
                    urls: 'stun:stun.pangadev.com:5349',
                    username: 'stunadmin',
                    credential: 'm7te8VLHjDi4sA6KTG8g',
                },
                // { urls: 'stun:stun.l.google.com:19302' },
            ],
        },
    },
};

import React, { useCallback, useEffect, useState } from 'react';

const useAnimationFrame = (callback: (t: number) => void) => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = React.useRef(0);
    const previousTimeRef = React.useRef(0);

    const animate = useCallback((time: number) => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;
            callback(deltaTime)
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }, [callback]);

    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, [animate]); // Make sure the effect runs only once
}

export interface GamepadRef {
    [key: number]: Gamepad;
}

export interface GamepadData {
    id: string;
    timestamp: number;
    tsPlayerEventCaptured: number;
    xInput: {
        wButtons: number,
        bLeftTrigger: number,
        bRightTrigger: number,
        sThumbLX: number,
        sThumbLY: number,
        sThumbRX: number,
        sThumbRY: number,
    },
    raw?: any
}

export const defaultGamepadData = {
    id: 'default',
    timestamp: 0,
    tsPlayerEventCaptured: 0,
    xInput: {
        wButtons: 0,
        bLeftTrigger: 0,
        bRightTrigger: 0,
        sThumbLX: 0,
        sThumbLY: 0,
        sThumbRX: 0,
        sThumbRY: 0,
    }
}

export interface GamepadRenderData {
    data: GamepadData,
    colors: {
        active: string,
        inactive: string
    },
    svgProps?: any
}

const DPAD_UP = 0x0001;
const DPAD_DOWN = 0x0002;
const DPAD_LEFT = 0x0004;
const DPAD_RIGHT = 0x0008;
const START = 0x0010;
const BACK = 0x0020;
const LEFT_THUMB = 0x0040;
const RIGHT_THUMB = 0x0080;
const LEFT_SHOULDER = 0x0100;
const RIGHT_SHOULDER = 0x0200;
const A = 0x1000;
const B = 0x2000;
const X = 0x4000;
const Y = 0x8000;

const check = (input: number, button: number): boolean => {
    return !!(button & input);
}
const toShort = (raw: number, reverse: boolean) => (((reverse ? 0 - raw : raw) + 1) * 32767.5 - 32768) | 0;
const toByte = (raw: number) => (raw * 255) | 0;
const toWord = (buttons: any) =>
    (buttons[12].pressed ? DPAD_UP : 0) |
    (buttons[13].pressed ? DPAD_DOWN : 0) |
    (buttons[14].pressed ? DPAD_LEFT : 0) |
    (buttons[15].pressed ? DPAD_RIGHT : 0) |
    (buttons[9].pressed ? START : 0) |
    (buttons[8].pressed ? BACK : 0) |
    (buttons[10].pressed ? LEFT_THUMB : 0) |
    (buttons[11].pressed ? RIGHT_THUMB : 0) |
    (buttons[4].pressed ? LEFT_SHOULDER : 0) |
    (buttons[5].pressed ? RIGHT_SHOULDER : 0) |
    (buttons[0].pressed ? A : 0) |
    (buttons[1].pressed ? B : 0) |
    (buttons[2].pressed ? X : 0) |
    (buttons[3].pressed ? Y : 0)
    ;
const hexToRgbA = (hex: string, opacity: string): string => {
    let c: number;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        const parts = hex.substring(1);
        if (parts.length === 6) {
            c = parseInt(parts, 16);
            return (
                'rgba(' +
                [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
                ',' +
                opacity +
                ')'
            );
        }
    }
    return 'rgba(0,0,0,0)';
};

export const convert = (gamepads: GamepadRef, gamepadIds: string[], includeRaw: boolean = true, player: string = '1') => {
    const raw = Object.values(gamepads).filter(x => x).map((g) => {
        return {
            axes: g.axes,
            buttons: g.buttons.map((b: any) => {
                return {
                    pressed: b.pressed,
                    touched: b.touched,
                    value: b.value,
                };
            }),
            id: g.id,
            index: g.index,
            mapping: g.mapping,
            timestamp: g.timestamp,
        };
    });

    return raw.map((r, i) => {
        const output: GamepadData = {
            id: gamepadIds[i],
            timestamp: r.timestamp,
            tsPlayerEventCaptured: Date.now(),
            xInput: {
                wButtons: toWord(r.buttons),
                bLeftTrigger: toByte(r.buttons[6].value),
                bRightTrigger: toByte(r.buttons[7].value),
                sThumbLX: toShort(r.axes[0], false),
                sThumbLY: toShort(r.axes[1], true),
                sThumbRX: toShort(r.axes[2], false),
                sThumbRY: toShort(r.axes[3], true),
            }
        }

        if (includeRaw) output.raw = r;
        return output;
    });
}

export const useGamepads = (connected: boolean, send: (message: string | Object) => void) => {
    const [gamepadIds] = useState<string[]>(['u1', 'u2', 'u3', 'u4']);

    useAnimationFrame((t: number) => {
        if (!connected) return;
        const g = navigator.getGamepads();
        const data = convert(g as Gamepad[], gamepadIds, false, '1');
        if (data.length < 1) return;
        data.forEach(d => send(d));
    });
}

export function Gamepads() {
    const [gamepads, setGamepads] = useState<GamepadData[]>();
    const [gamepadIds] = useState<string[]>(['1', '2', '3', '4']);

    useAnimationFrame((t: number) => {
        const g = navigator.getGamepads();
        const data = convert(g as Gamepad[], gamepadIds, false, '1');
        setGamepads(data)
    })

    return (
        <div className="Gamepads">
            <h1>Gamepads</h1>
            <pre>{JSON.stringify(gamepads, null, 4)}</pre>
        </div>
    );
}
import { Box, Button, Stack, Typography } from "@mui/material";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import axios from "axios";
import { useRef } from "react";

export const useMessage = () => {

    const playjector = axios.create({
        baseURL: "http://localhost:11013"
    });


    const send = (message: any) => {
        playjector.post('/', message);
    }

    return {
        send
    }
}

export const Message = () => {

    const { send } = useMessage();
    const text = useRef<HTMLTextAreaElement>(null);
    
    const starter = {
        "userId": 0,
        "tag": 63,
        "timestamp": 0,
        "type": "mouse",
        "tsPlayerEventCaptured": 1674565661673,
        "tsPlayerSentToHost": 1674565661676,
        "tsHostReceivedFromPlayer": 1674565661676,
        "tsHostSentToExtension": 1674565661676,
        "tsExtensionReceivedFromHost": 1674565661676,
        "tsExtensionSentToPlayjector": 1674565661676,
        "deviceId": "screen:0:0",
        "sourceSettings": [
            {
                "autoGainControl": true,
                "channelCount": 1,
                "deviceId": "loopback",
                "echoCancellation": true,
                "latency": 0.01,
                "noiseSuppression": true,
                "sampleRate": 48000,
                "sampleSize": 16,
                "kind": "audio"
            },
            {
                "aspectRatio": 1.7777777777777777,
                "cursor": "always",
                "deviceId": "screen:0:0",
                "displaySurface": "monitor",
                "frameRate": 60,
                "height": 1440,
                "logicalSurface": true,
                "resizeMode": "crop-and-scale",
                "width": 2560,
                "kind": "video"
            }
        ],
        "subtype": "mouseLocation",
        "mouseLocation": {
            "windowScreenX": 1024,
            "windowScreenY": 73,
            "windowInnerHeight": 1454,
            "windowOuterHeight": 1215,
            "topBarHeight": 110,
            "scale": 76,
            "targetWidth": 1592,
            "targetHeight": 896,
            "tsPlayerEventCaptured": 1674565661673,
            "boundedX": 639,
            "boundedY": 516,
            "videoWidth": 2560,
            "videoHeight": 1440,
            "screenX": 1511,
            "screenY": 576,
            "clientX": 638,
            "clientY": 516,
            "ctrlKey": false,
            "shiftKey": false,
            "altKey": false,
            "metaKey": false,
            "button": 0,
            "buttons": 0,
            "pageX": 638,
            "pageY": 516,
            "x": 638,
            "y": 516,
            "offsetX": 639,
            "offsetY": 516,
            "movementX": 3,
            "movementY": -1,
            "layerX": 638,
            "layerY": 516,
            "tsPlayerSentToHost": 1674565661676
        }
    }

    const handleClick = () => {
        send(JSON.parse(text?.current?.value || '{}'));
    }

    return <>
        <Box sx={{ margin: '2rem' }}>
            <Stack spacing="2rem">
                <Typography>Messaging Tests</Typography>
                <TextareaAutosize ref={text} minRows={20} defaultValue={JSON.stringify(starter, null, 4)} />
                <Button onClick={handleClick} variant="contained" sx={{ width: "12rem" }}>Send</Button>
            </Stack>
        </Box>
    </>
}

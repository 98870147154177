import { Box, Button, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import Peer from 'peerjs';
import { config } from './config';
import { createId } from '@paralleldrive/cuid2';

export const useReceiver = (videoPlayer: React.RefObject<HTMLVideoElement>) => {

    const [name, setName] = useState<string>(createId());
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [isJoined, setIsJoined] = useState<boolean>(false);
    const [doAutoReconnect, setDoAutoReconnect] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    // const [stream, setStream] = useState<MediaStream>();
    const [remote, setRemote] = useState<string>('');
    
    const peer = useRef<Peer>();
    const connection = useRef<Peer.DataConnection>();
    const dataCallback = useRef<(data: any) => void>((data) => { });

    const getNewPeer = (): Peer => {
        console.log('Creating new peer connection...');
        const newPeer = new Peer(name, config.peerServer);

        newPeer.on('error', (error) => {
            setMessage('Error: ' + error.type);
        });

        newPeer.on('open', () => {
            setIsConnected(true);
            setMessage('Connected to peer server');
        });

        // This "connection" means a remote peer has connected, not that this
        // peer connected to a peer server (which generates "open")
        newPeer.on('connection', (c: Peer.DataConnection) => {
            setMessage('Received remote peer connection: ' + c.peer);
            setRemote(c.peer);
            // peer.current?.call(c.peer, stream!);
            c.on('data', (data) => {
                dataCallback.current(data);
            });

            c.on('error', (error) => {
                console.log(c.peer + ' error', error);
                // Dispose of bad connection
                // if (!player.current?.destroyed) {
                //     player.current?.destroy();
                // }
                setIsConnected(false);
            });

            c.on('close', () => {
                console.log(c.peer + ' closed');
                // Dispose of bad connection
                // if (!player.current?.destroyed) {
                //     player.current?.destroy();
                // }
                setIsConnected(false);
            });

            c.on('open', () => {
                console.log(c.peer + ' open');
                //connection.current = conn;
                c.send('I am player');
            });

        });

        newPeer.on('call', (call) => {
            call.answer();
            call.on('stream', (remoteStream) => {
                console.log('got remote stream');
                if (videoPlayer && videoPlayer.current) {
                    videoPlayer.current.srcObject = remoteStream;
                    videoPlayer.current.play();
                    videoPlayer.current.muted = false;
                }
            });
        });

        newPeer.on('disconnected', () => {
            setIsConnected(false);
            // Attempt reconnection automatically, if set
            if (doAutoReconnect) {
                newPeer.reconnect();
            }
        });

        newPeer.on('close', () => {
            console.log('Peer server connection destroyed');
            setIsConnected(false);
        });

        return newPeer;
    }

    const connect = () => {
        if (!name) {
            setMessage('A host name is required to start allowing peer connections');
            return;
        }

        if (peer.current && !peer.current.destroyed) {
            // Already have a peer object, re-use if possible
            if (peer.current.id !== name) {
                // Trying to start a new peer by name, must make a new one
                peer.current.destroy();
                peer.current = getNewPeer();
            } else {
                if (!peer.current.disconnected) {
                    // Already connected with this ID, just continue
                    return;
                } else {
                    // Trying to reconnect with same name
                    peer.current.reconnect();
                }
            }
        } else {
            // No peer at all yet
            peer.current = getNewPeer();
        }
    }

    const disconnect = () => {
        // Kill all connections
        peer.current?.destroy();
        setIsConnected(false);
        setIsJoined(false);
        setMessage('Disconnected from peer server');
        if (doAutoReconnect) {
            connect();
            setMessage('Attempting reconnection');
        }
    }

    const join = (host: string) => {
        connection.current = peer.current?.connect(host);
        setIsJoined(true);
    }

    const updateName = (target: string) => {
        setName(target);
    }

    const setAutoReconnect = (auto: boolean) => {
        setDoAutoReconnect(auto);
        setMessage('Autoreconnect set to ' + (auto ? 'true' : 'false'));
    }

    const setDataCallback = (f: (data: any) => void) => {
        dataCallback.current = f;
    }

    const send = (message: string | Object) => {
        if (!connection.current) return;
        const m = (typeof message === 'object') ? JSON.stringify(message) : message;
        connection.current!.send(m);
    }

    useEffect(() => {
        window.addEventListener('beforeunload', disconnect);
        return () => {
            window.removeEventListener('beforeunload', disconnect);
        }
    });

    return {
        name: name,
        peer: peer.current,
        remote: remote,
        message: message,
        connect: connect,
        disconnect: disconnect,
        join: join,
        send: send,
        setAutoReconnect: setAutoReconnect,
        autoreconnect: doAutoReconnect,
        connected: isConnected,
        joined: isJoined,
        updateName: updateName,
        setDataCallback: setDataCallback
    }
}

export const Receiver = () => {

    const videoPlayer = useRef<HTMLVideoElement>(null);
    
    const receiver = useReceiver(videoPlayer);
    const message = useRef<HTMLInputElement>(null);

    return <>
        <Box sx={{ m: '2rem', position: 'relative' }}>
            <Box sx={{ mb: '2rem' }}>
                <Typography>Peer Testing</Typography>
                <Typography>ID: {receiver.name}</Typography>
            </Box>
            <Box sx={{ mb: '2rem' }}>
                <Stack>
                    <TextField inputRef={message} label="Message" />
                </Stack>
            </Box>
            <Box sx={{ m: '2rem', position: 'relative' }}>
                <Box sx={{
                    textAlign: "center", maxHeight: "20vh", maxWidth: "20vw", "> video": {
                        maxHeight: "100%", maxWidth: "100%", objectFit: 'contain',
                        width: '20vw',
                        height: '20vh',
                        position: 'fixed',
                        top: 0,
                        left: 0
                    }
                }}>
                <video ref={videoPlayer} playsInline autoPlay></video>
                </Box>
            </Box>
            <Box sx={{ mb: '2rem' }}>
                <Stack>
                    <Button variant="contained" sx={{ width: "8rem", mt: "1rem", mr: "1rem" }} onClick={() => receiver.connect()}>Connect</Button>
                    <Button variant="contained" sx={{ width: "8rem", mt: "1rem", mr: "1rem" }} onClick={() => receiver.join('trey')}>Join</Button>
                    <Button variant="contained" sx={{ width: "8rem", mt: "1rem", mr: "1rem" }} onClick={() => receiver.send({ message: 'thing' })}>Send</Button>
                </Stack>
            </Box>
            <Box sx={{ mb: '2rem' }}>
            </Box>
        </Box>
    </>
}

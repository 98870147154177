import { Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { readObjectPrototype, translateXY } from "./utilities";

export const useMouse = (send: (message: string | Object) => void) => {

    const [mouseEvent, setMouseEvent] = useState({
        event: '',
        location: {
            windowScreenX: 0,
            windowScreenY: 0,
            windowInnerHeight: 0,
            windowOuterHeight: 0,
            topBarHeight: 0,
            scale: 1,
            boundedX: 0,
            boundedY: 0,
            videoWidth: 0,
            videoHeight: 0,
            targetWidth: 2560,
            targetHeight: 1440,
            tsPlayerEventCaptured: 0
        },
        data: {}
    });

    useEffect(() => {
        send({
            event: mouseEvent.event,
            data: {
                ...mouseEvent.location,
                ...mouseEvent.data
            }
        });
    }, [mouseEvent.data, mouseEvent.event, mouseEvent.location, send])

    const handleMouseEvent = useCallback((e: MouseEvent) => {
        const target = e.target as HTMLVideoElement;
        const boundedX = Math.max(Math.min(e.offsetX, ((target.offsetWidth || 0) - 1)), 0);
        const boundedY = Math.max(Math.min(e.offsetY, ((target.offsetHeight || 0) - 1)), 0);
        const scale = Math.round(((e.screenX - window.screenX) / e.clientX) * 100);

        const location = {
            windowScreenX: window.screenX,
            windowScreenY: window.screenY,
            windowInnerHeight: window.innerHeight,
            windowOuterHeight: window.outerHeight,
            topBarHeight: Math.round(window.outerHeight - ((window.innerHeight * scale) / 100)),
            scale: scale,
            targetWidth: target.offsetWidth,
            targetHeight: target.offsetHeight,
            tsPlayerEventCaptured: Date.now(),
            boundedX: boundedX,
            boundedY: boundedY,
            videoWidth: target.videoWidth,
            videoHeight: target.videoHeight
        }

        switch (e.type) {
            case 'mousemove':

                setMouseEvent(() => {
                    return {
                        event: "mouseLocation",
                        location: location,
                        data: { ...readObjectPrototype(e) }
                    };
                });

                break;

            case 'mousedown':
                // console.log('mousedown');
                setMouseEvent(() => {
                    return {
                        event: "mouseDown",
                        location: location,
                        data: { ...readObjectPrototype(e) }
                    };
                });
                break;

            case 'mouseup':
                // console.log('mouseup');
                setMouseEvent(() => {
                    return {
                        event: "mouseUp",
                        location: location,
                        data: { ...readObjectPrototype(e) }
                    };
                });
                break;

            case 'wheel':
                setMouseEvent(() => {
                    return {
                        event: "mouseWheel",
                        location: location,
                        data: { ...readObjectPrototype(e) }
                    };
                });
                break;
        }
    }, []);

    const ref = useRef<HTMLVideoElement | null>(null);

    const callbackRef = useCallback(
        (node) => {
            if (ref.current) {
                ref.current.removeEventListener("wheel", handleMouseEvent);
                ref.current.removeEventListener("mousemove", handleMouseEvent);
                ref.current.removeEventListener("mouseup", handleMouseEvent);
                ref.current.removeEventListener("mousedown", handleMouseEvent);
            }

            ref.current = node;

            if (ref.current) {
                ref.current.addEventListener("wheel", handleMouseEvent);
                ref.current.addEventListener("mousemove", handleMouseEvent);
                ref.current.addEventListener("mouseup", handleMouseEvent);
                ref.current.addEventListener("mousedown", handleMouseEvent);
            }
        },
        [handleMouseEvent]
    );

    return { ref: ref, cb: callbackRef, mouseEvent };
};

export const Mouse = () => {
    const send = (message: string | Object) => {
        // console.log(message);
    }

    const { cb, mouseEvent } = useMouse(send);
    const [dotLocation, setDotLocation] = useState({ x: 0, y: 0 });
    const [debug, setDebug] = useState<boolean>(true);
    const [aspect, setAspect] = useState<string>('tall');
    const player = useRef<HTMLVideoElement>(null);

    const tw = 600;
    const vw = 1067;
    const vh = 600;

    useEffect(() => {
        const th = (aspect === 'tall' ? 500 : (aspect === 'wide' ? 300 : 338));
        const xy = translateXY(mouseEvent.location.boundedX, mouseEvent.location.boundedY, tw, th, vw, vh);
        setDotLocation(xy);
    }, [mouseEvent, aspect]);

    return <>
        <Box sx={{ position: "absolute", border: "1px solid lightblue", top: "0", left: "0", width: "1067px", height: "600px" }}></Box>
        <Box sx={{ height: "3px", width: "3px", backgroundColor: "#ff0000", position: "absolute", left: `` + dotLocation.x + 'px', top: `` + dotLocation.y + 'px' }}></Box>
        <Box sx={{ m: "2rem" }}>
            <Typography variant="h3">Mouse Experiments</Typography>
            <Stack direction="row">
                <Box sx={{
                    maxWidth: ''.concat(tw.toString(), "px"),
                    maxHeight: ''.concat((aspect === 'tall' ? 500 : (aspect === 'wide' ? 300 : 338)).toString(), "px"),
                    backgroundColor: "#333",
                    "> div > video": {
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: 'contain',
                        width: ''.concat(tw.toString(), "px"),
                        height: ''.concat((aspect === 'tall' ? 500 : (aspect === 'wide' ? 300 : 338)).toString(), "px")
                    }
                }}>
                    <Box sx={{ zIndex: "1000" }} ref={cb} ><video ref={player} playsInline autoPlay src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"></video></Box>
                    <Box sx={{ mt: "1rem" }}>
                        <Typography sx={{ mb: "0.5rem", color: "lightblue" }}>Light blue box simulates source display, red dot is translated x, y</Typography>
                        <Stack direction="row" spacing="2rem">
                            <Stack direction="row" spacing="0.5rem">
                                <Button variant="contained" onClick={() => { setAspect('tall') }}>Tall</Button>
                                <Button variant="contained" onClick={() => { setAspect('wide') }}>Wide</Button>
                                <Button sx={{ mr: "2rem" }} variant="contained" onClick={() => { setAspect('exact') }}>Exact</Button>
                            </Stack>
                            <Stack direction="row" spacing="0.5rem" sx={{ mt: "1rem" }}>
                                <Button variant="outlined" onClick={() => { player.current!.play() }}>Play</Button>
                                <Button variant="outlined" onClick={() => { setDebug((prev) => !prev) }}>Debug</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
                {debug ? <Box sx={{ mx: "2rem", fontSize: "0.6rem" }}>
                    <pre>{JSON.stringify(mouseEvent, null, 4)}</pre>
                </Box> : ``}
            </Stack>
        </Box>
    </>
}


import { Button, Card, CardContent, Typography } from '@mui/material';
import Peer from 'peerjs';
import { useEffect, useState } from 'react';
import { getRoom } from './utilities';

interface localProps {
}

export const Conference = (props: localProps) => {

    const [participants, setParticipants] = useState<Peer.DataConnection[]>([]);
    const [room, setRoom] = useState<Peer | null>();
    const [peerId, setPeerId] = useState<string>('Awaiting ID');
    const [message, setMessage] = useState<string>('waiting...');
    const [messageSent, setMessageSent] = useState<boolean>(true);
    const [sharing] = useState<boolean>(false);
    const [players, setPlayers] = useState<string[]>([]);

    useEffect(() => {
        if (!room) {
            const newPeer = new Peer(getRoom(), {
                host: 'peerserver.pangadev.com',
                port: 443,
                secure: true,
                debug: 0,
                config: { 'iceServers': [{ 'urls': ['stun:stun.l.google.com:19302'] }] }
            });

            newPeer.on('open', () => {
                setPeerId(newPeer.id);
            });

            newPeer.on('connection', c => {
                const peerId = c.peer;

                if (!players.includes(peerId)) {
                    setParticipants(participants => [...participants, c]);
                    setPlayers(players => ([...players, peerId]));
                }

                c.on('data', (data: string) => {
                    setMessage(data);
                    setMessageSent(false);
                });
            });

            setRoom(newPeer);
        }
    }, [players, participants, room]);

    useEffect(() => {
        if (messageSent) return;
        // Forward new messages to all participants
        participants.forEach(p => {
            p.send(message);
        });
        setMessageSent(true);
    }, [message, participants, messageSent]);

    const share = () => {
        // if (!peer || !player) return;
        // navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
        //     peer.call(player, stream);
        //     setSharing(true);
        // });
    }

    return <Card sx={{ my: 2 }}>
        <CardContent>
            <Typography>Conference</Typography>
            <Typography variant='h4'>{peerId}</Typography>
            <Typography>Players</Typography>
            <ul>
                {players.map(p => <li key={p}>{p}</li>)}
            </ul>
            <Typography>Connections</Typography>
            <ul>
                {participants.map(p => <li key={p.peer}>{p.peer}</li>)}
            </ul>
            <Typography>Latest message: {message}</Typography>
            {sharing ? '' : <Button onClick={share}>Share</Button>}

        </CardContent>
    </Card>
}
